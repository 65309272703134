import { Link } from 'gatsby';
import React, { useState } from 'react';

import { useScrollPosition } from "../hooks/useScrollPosition"
import { logout } from '../services/auth';

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  let user;
  if (typeof window !== 'undefined') {
    user = window.localStorage.getItem('gatsbyUser');
  }

  if ( user === null ) {
    return (<></>);
  } else {
    return (
      <>
        <nav className="flex items-center p-3 flex-wrap">
          <button
            className={`inline-flex p-3 rounded ml-auto outline-none ${isExpanded ? 'expanded' : ''}`}
            data-target="#navigation"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <i className="material-icons">MENU</i>
          </button>
          <div
            className={`${
              isExpanded ? `block` : `hidden`
            } top-navbar w-full`}
            id="navigation"
          >
            <div className="w-full items-start flex flex-col">
              <Link
                className="text-center w-full px-3 py-2 items-center justify-center"
                to="/"
              >
                Home
              </Link>
              <Link
                className="text-center w-full px-3 py-2 items-center justify-center"
                to="/gastenboek"
              >
                Gastenboek
              </Link>
              <a href="#" onClick={logout}
                className="text-center w-full px-3 py-2 items-center justify-center"

              >
                Uitloggen
              </a>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
