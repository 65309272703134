import PropTypes from 'prop-types';
import React from 'react';
import Header from './header';
import { navigate } from 'gatsby';

import { isLoggedIn } from '../services/auth';

function Layout({ children }) {
  if (typeof window !== 'undefined') {
    if (
      !isLoggedIn() &&
      window.location.pathname !== `/login`) {
      navigate(`/login`);
      return null;
    }
  }
  return (
    <>
      <div className="backround__container">
        <div className="main__container">
          <Header />

          <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
            {children}
          </main>
        </div>
      </div>

      <footer
        className="fixed bottom-0 w-full">
        <span>15-10-2021</span>
      </footer>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
